import {HttpInterceptorFn} from '@angular/common/http';
import {catchError, EMPTY, from, of, switchMap, take, throwError} from "rxjs";
import {Inject, inject, NgZone, PLATFORM_ID} from "@angular/core";
import {Router} from "@angular/router";
import {StorageDataKey, StorageService} from "../../service/storage.service";
import {ApiService} from "../api.service";
import {UserRepositoryService} from "../../repository/user-repository.service";
import {isPlatformBrowser} from "@angular/common";
import {PlatformService} from "../../service/platform.service";

//@ts-ignore
export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const router = inject(Router);
  const zone = inject(NgZone);
  const storage = inject(StorageService);
  const api = inject(ApiService);
  const userRepo = inject(UserRepositoryService);
  const platform =  inject(PlatformService);
  function redirectToSignup() {
    zone.run(() => {
      if (!(router.url.includes('signup') || router.url.includes('signin'))) {
        storage.clearLocalData(StorageDataKey.apiUser);
        storage.clearLocalData(StorageDataKey.jwtToken);
        router.navigate(['/signup'], {
          queryParams: {
            returnUrl: router.url
          }
        });
      }
    });
  }
  return next(req).pipe(
   catchError( error => {
     const isBrowser = platform.isBrowser;
     if (isBrowser) {
       console.log('Interceptor Error')
       console.dir(error);
       const data = error.error;
       switch (error.status) {
         case 400:
           if (data.message === 'Some files already exists') {
             return throwError(() => new ChecksumError(data.message, data['toConfirm'],));
           }
           break;
         case 401:
           if (data.error === 'Unauthorized' || data.message === 'Unauthorized') {
             return from(api.refreshToken(
               {
                 userId: userRepo.meLazy().cache.id,
                 jwtToken: storage.getLocalData(StorageDataKey.jwtToken)!,
                 fcmToken: storage.getLocalData(StorageDataKey.fcmToken),
                 browserId: storage.getLocalData(StorageDataKey.browserId) ?? '',
               }
             )).pipe(
               take(1),
               switchMap((refreshResult) => {
                 storage.setLocalData(StorageDataKey.jwtToken, refreshResult.jwtToken);
                 return next(req.clone({
                   headers: req.headers.set('Authorization', `Bearer ${refreshResult.jwtToken}`),
                 }));
               }),
               catchError((_) => {
                 redirectToSignup();
                 return throwError(() => new UnauthorizedError());
               })
             );
           } else {
             redirectToSignup();
             return throwError(() => new UnauthorizedError());
           }
         case 404:
           if (data.message === 'User not found') {
             return throwError(() => new UserNotFoundError());
           }
           break;
         case 409:
           if (data.message === 'user_exists') {
             return throwError(() => new UserExistsError());
           }
           break;
         case 422:
           if (data.message instanceof Array) {
             let message: any = data.message[0];
             if (message.property == 'password' && message['constraints'].matches.length) {
               return throwError(() => new ValidationError('Password must contains uppercase letter, lowercase letter and special char. Password length must be 8-32 symbols'));
             }
             if (message.property == 'password' && message['constraints']['isEmail'].length) {
               return throwError(() => new ValidationError('Enter valid email address'));
             }
           }
           break;
         case 500:
           if (req.url.includes('/broadcast')) {
             zone.run(() => {
               router.navigate(['messages/lists']);
             });
             return throwError(() => error);
           }
           if (req.url.includes('/me')) {
             redirectToSignup();
             return throwError(() => new UnauthorizedError());
           }
           return throwError(() => error)
       }
       return throwError(() => error);
     }
     return of(EMPTY);
   }),
  );
};

export abstract class ApiError {
  redirectToLogin = false;
}
export class UnauthorizedError implements ApiError {
  redirectToLogin = true;
}
export class UserNotFoundError implements ApiError {
  redirectToLogin = true;
}
export class UserExistsError implements ApiError {
  redirectToLogin = false;
}
export class ValidationError implements ApiError {
  redirectToLogin = false;
  constructor(public message: string) {
  }
}
export class ChecksumError implements ApiError {
  redirectToLogin = false;
  constructor(public message: string, public toConfirm: []) {
  }
}
